import React from "react";
import Styles from "./CourtInfoCC.module.scss";

const InfoCreditChecks = (props) => {
  return (
    <section>
      {props.data.map((data, index) => {
        return (
          <div
            className={`${Styles.InfoCreditCheckInner} ${Styles.InfoCreditCheckWrap}`}
            key={index}
          >
            <div className={Styles.InfoCreditCheckLeft}>
              <div className={Styles.InfoCreditCheckHeader}>
                <h2>{data.header}</h2>
                {data.Illustration ? (
                  <div className={Styles.InfoCreditRightMob}>
                    {data.Illustration.map((ImageMob, i) => (
                      <img
                        src={require(`../../../images/LP/InfoCreditCheck/${ImageMob.path}.png`)}
                        key={i}
                      />
                    ))}
                  </div>
                ) : null}
                <p>{data.para}</p>
              </div>
              {data.IconLink ? (
                <div className={Styles.InfoCreditInner}>
                  {data.IconLink.map((checkIcon, i) => (
                    <div className={Styles.InfoCreditCard} key={i}>
                      <img
                        src={require(`../../../images/LP/InfoCreditCheck/${checkIcon.path}.png`)}
                        key={i}
                      />
                      <p>{checkIcon.desc}</p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {data.Illustration ? (
              <div className={Styles.InfoCreditRight}>
                {data.Illustration.map((Image, i) => (
                  <img
                    src={require(`../../../images/LP/InfoCreditCheck/${Image.path}.png`)}
                    key={i}
                  />
                ))}
              </div>
            ) : null}
          </div>
        );
      })}
    </section>
  );
};

export default InfoCreditChecks;
