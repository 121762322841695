import React from "react";
import styles from "../../components/Landers/landers.module.scss";

export default ({ steps, candidate, path, h2, subHeader }) => (
  <section className={styles.steps}>
    <div className="tag">{subHeader ? subHeader : "Easy onboarding"}</div>
    <h2>{h2 ? h2 : "Three simple steps to get started"}</h2>
    <div className={styles.body}>
      <div className={styles.grid}>
        <img
          data-src={`https://assets-sv-in.gumlet.io/LP/grid-bg.svg`}
          alt=""
        />
        <img
          data-src={candidate}
          className={styles.candidate}
          alt="candidate"
        />
      </div>
      <div className={styles.list}>
        {steps.map(({ h5, p }, i) => (
          <div key={i}>
            <img
              data-src={`https://assets-sv-in.gumlet.io/LP/${path}/Steps/${
                i + 1
              }.svg`}
              alt="step"
            />
            <div>
              <h5>{h5}</h5>
              <p>{p}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);
