import React from "react";
import Styles from "./BrandsTemp.module.scss";

const BrandsTemp = (props) => {
  return (
    <>
      <div className={Styles.BrandsTempWrap}>
        <section>
          {props.data.map((data, index) => {
            return (
              <div className={Styles.BrandsTempInner} key={index}>
                <div className={Styles.BrandsTempHeader}>
                  <h2>{data.header}</h2>
                </div>
                {data.Logos ? (
                  <div className={Styles.BrandsTempLogos}>
                    {data.Logos.map((Image, i) => (
                      <img src={Image.path} key={i} />
                    ))}
                  </div>
                ) : null}
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default BrandsTemp;
