import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/BrandsTemp";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import CourtInfoCreditCheck from "../../../components/Landers/CourtInfoCreditCheck";
import LocalStyles from "./court-record-check.module.scss";
import DataInfo from "../../../components/Data";

const steps = [
  {
    h5: "Upload candidate information",
    p:
      "Initiate the verification process as soon as your candidates complete their personal profile. ",
  },
  {
    h5: "Scan Court Records Database",
    p: "Identify if a candidate has any pending criminal or civil lawsuits.",
  },
  {
    h5: "Get real time updates",
    p:
      "Keep tabs on the verification process and get notified if there’s any discrepancy in real time.",
  },
];

const testimonials = [
  {
    name: "Harshavardhan N ",
    company: "Razorsharp Technologies Pvt. Ltd.",
    p: `SpringVerify is very simple to use. Initiating checks are extremely easy compared to previous product I have used so far.
    Their candidate and the HR portals are by far the best. Our company relies on SpringVerify everytime we run background checks on candidates. `,
  },
  {
    name: "Ravindra M ",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
    This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Mariam M ",
    company: "Touchkin eServices Pvt. Ltd.",
    p: `It's the first time we are doing background checks. But still, SpringVerify gave us very good clarity on the process.
    They helped us in setting up the account quickly and and helped us perform checks without any hassle.`,
  },
];

export default () => (
  <div className={`${LocalStyles.CRVerification} ${styles.ref}`}>
    <SEO
      title="Instant Court Record Verification Service| SpringVerify USA"
      desc="Screen candidates for Court Records to discover non-criminal suits filed by ex-employers, corporations with SpringVerify's world class background verification service."
      url="https://in.springverify.com/screenings/court-record-check/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+(court+record+check).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Perform Court Record \nVerifications Effortlessly"}
        p="Identify if a candidate is charged with legal or criminal lawsuits. Stay aware of potential threats and make informed hiring decisions with SpringVerify."
        img={
          "https://assets-sv-in.gumlet.io/LP/court-record-check/CRBanner.svg"
        }
      />
    </div>
    <Brands data={DataInfo.CRBrandsTemp} />
    <section>
      <SectionTemplate
        img="court-record-check/court-record.svg"
        h2={`Importance of Court Record \nVerifications`}
        p={`Court Record Verification provides a comprehensive insight on the candidate’s \ncriminal and civil lawsuits if any. Verifying your candidate’s court records helps you.`}
        ul={[
          "Identify potentially risky hires",
          "Stay compliant with government laws",
          "Ensure organizational safety",
        ]}
      />
    </section>
    <section>
      <SectionTemplate
        reversed
        img="court-record-check/information.svg"
        h2={`Information you'll know from \nCourt Record Verifications`}
        p={`Court Record Verification helps employers gain additional information about \nyour candidates such as:`}
        ul={[
          "Liens and foreclosures",
          "Personal restraining orders",
          "Civil rights violations",
          "Tax claims and disputes",
          "Criminal cases",
        ]}
      />
    </section>
    <CourtInfoCreditCheck data={DataInfo.InfoCourtCovered} />
    <ThreeSteps
      subHeader="Easy onboarding"
      steps={steps}
      candidate="https://assets-sv-in.gumlet.io/LP/court-record-check/three-steps.png"
      path="court-record-check"
    />
    <section>
      <SectionTemplate
        reversed
        img="court-record-check/ideal-choice.svg"
        h2={`SpringVerify - Ideal choice for \nBackground Screening`}
        p={`SpringVerify is designed to scale hiring by avoiding the cost of wrong hires. Here's why fast-growing startups and enterprises trust SpringVerify.`}
        ul={[
          "20+ checks in one place",
          "Reliable documentation and reports",
          "Seamless integration with HRMS and ATS tools you already use",
          "Flexible and transparent pricing",
          <span>
            World's first <b>"Verify on Whatsapp"</b>
          </span>,
        ]}
      />
    </section>
    <Testimonials testimonials={testimonials} path="court-record-check" />
    <GetStarted
      img="court-record-check/CRGetStarted.svg"
      h3={"Ready to get started \nwith us?"}
      p="Partner with the most robust background verification service for a safe and secure workplace."
    />
    <Footer />
  </div>
);
